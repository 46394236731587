import React, { createContext, useContext, useEffect, useState } from "react";
import { checkInApi, toDoListApi } from "store/api";
import { useUser } from "./useUser";
import { useHistory } from 'react-router-dom'

const TaskContext = createContext({});

export const useTasks = () => useContext(TaskContext);

const TaskContextProvider = ({ children }) => {
    const [tasks, setTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [withinSevenDays, setWithinSevenDays] = useState(false);
    const [taskCount, setTaskCount] = useState(0);
    const [weekAfterFirstQuiz, setWeekAfterFirstQuiz] = useState(false);

    const { isLoggedIn } = useUser();
    const history = useHistory();

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const getTasks = async () => {
        try {
            const data = await toDoListApi.fetchToDoList();

            if (!data) {
                console.error("Error fetching tasks: Data is undefined.");
                setIsLoading(false);
                return;
            }

            const today = new Date().toDateString();
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

            // Function to check if the task is completed today
            const isCompletedToday = (completedAtArray) => {
                if (!completedAtArray) return false;
                return completedAtArray.some(dateString => new Date(dateString).toDateString() === today);
            };

            // Filter tasks created within the last 7 days
            const recentTasks = data.filter(item => {
                const createdAtDate = new Date(item.created_at);
                return createdAtDate >= oneWeekAgo;
            });

            // Aggregate tasks by their IDs
            const tasksMap = new Map();

            recentTasks.forEach(item => {
                if (!tasksMap.has(item.id)) {
                    tasksMap.set(item.id, {
                        ...item,
                        completed_at: item.completed_at ? [item.completed_at] : null
                    });
                } else {
                    const existingTask = tasksMap.get(item.id);
                    existingTask.completed_at.push(item.completed_at);
                    tasksMap.set(item.id, existingTask);
                }
            });

            // Convert the map back to an array and exclude tasks completed today
            const aggregatedTasks = Array.from(tasksMap.values()).filter(item => {
                return !isCompletedToday(item.completed_at);
            });

            // Filter tasks created within the last week
            const tasksWithinLastWeek = aggregatedTasks.filter(item => {
                const createdAtDate = new Date(item.created_at);
                return createdAtDate >= oneWeekAgo && isCompletedToday(item.most_recent_completed_at);
            });

            // Filter out tasks older than a week
            const oldTasks = data.filter(item => new Date(item.created_at) < oneWeekAgo);

            // Sort the old tasks by created_at date in descending order
            const sortedOldTasks = oldTasks.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            // Find the most recent created_at date among the sorted old tasks
            const mostRecentOldDate = sortedOldTasks.length > 0 ? new Date(sortedOldTasks[0].created_at) : null;

            // Return all old tasks with the most recent created_at date
            const mostRecentCompletedTasks = sortedOldTasks.filter(item => new Date(item.created_at).getTime() === mostRecentOldDate.getTime() && item.completed_at !== null);

            const sortedAllTasks = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            const mostRecentResult = sortedAllTasks[0]?.created_at;
            setWithinSevenDays(isWithinSevenDays(mostRecentResult));

            const firstResult = data[0].created_at;
            setWeekAfterFirstQuiz(!isWithinSevenDays(firstResult));

            setCompletedTasks(mostRecentCompletedTasks);
            setTasks(aggregatedTasks);
            setIsLoading(false);
            setTaskCount(tasksWithinLastWeek.length);
        } catch (error) {
            console.error("Error fetching tasks:", error);
            setIsLoading(false);
        }
    };

    const isWithinSevenDays = (dateString) => {
        const createdDate = new Date(dateString);
        const currentDate = new Date();
        const differenceInTime = currentDate.getTime() - createdDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        return differenceInDays <= 7;
    };

    useEffect(() => {
        if (isLoggedIn) {
            getTasks();
        }
    }, [isLoggedIn, history]);

    return (
        <TaskContext.Provider
            value={{
                completedTasks,
                tasks,
                setTasks,
                isLoading,
                setIsLoading,
                withinSevenDays,
                setWithinSevenDays,
                weekAfterFirstQuiz,
                getTasks,
                taskCount,
            }}
        >
            {children}
        </TaskContext.Provider>
    );
};

export default TaskContextProvider;
