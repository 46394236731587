const STORAGE_KEY = "JFW_"

export const clearItem = (key) => {
    try {
        localStorage.removeItem(STORAGE_KEY + key);
    } catch (error) {
        console.log(error.message);
    }
};

export const storeItem = (key, value) => {
    try {
        localStorage.setItem(STORAGE_KEY + key, value);
    } catch (error) {
        console.log(error.message);
    }
};

export const retrieveItem = key => {
    let item = null;
    try {
        item = localStorage.getItem(STORAGE_KEY + key) || null;
    } catch (error) {
        console.log(error.message);
    }
    return item;
};
