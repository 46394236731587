const initState = {
    audio_url: '',
    video_url: '',
    recordingList: []
}

export default (state = initState, action) => {
    switch(action.type){
        case 'RECORDAUDIO':
            return{
                ...state,
                audio_url:action.payload
            }
        case 'SAVESUBMISSION':
            return{
                ...state,
                submissionsId: action.payload
            }
        case 'SAVESUBMISSIONKIND':
            return{
                ...state,
                submissionKind: action.payload
            }
        case 'SAVERESPONSE':
            return{
                ...state,
                responseId: action.payload
            }
        case 'RECORDVIDEO':
            return{
                ...state,
                video_url:action.payload
            }
        case 'DELETE':
            return state;
        case 'FETCHRECORDINGS':
            return {
                ...state,
                recordingList: [...action.payload]
            }
        default:
            return state;
    }
}
