import COLORS from "../constants/colors";
const themes = [
  {
    id: "joyfully",
    source: {
      palette: {
        primary: {
          light: COLORS.inactive,
          main: "#10545D",
          dark: "#010101CC",
          muted: "#01010163",
          inactive: "#E0E4DA",
          green: "#10545D",
          success: "#10545D",
          danger: "#d01212",
        },
        secondary: {
          main: COLORS.secondary,
          light: "#FEE9D6",
        },
      },
      shape: {
        borderRadius: 15,
      },
      background: COLORS.background,
      listItem: {
        boxShadow: "0px 3.74029px 10.7746px rgba(53, 55, 81, 0.05)",
      },
      typography: {
        fontFamily: "Nunito",
        fontWeight: 400,
        fontSize: 14,
        color: COLORS.dark,
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 500,
          fontSize: 20,
        },
        body2: {
          fontWeight: 400,
          fontSize: 14,
        },
      },
      shape: {
        borderRadius: 15,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "capitalize",
              fontSize: "1.2rem",
              fontFamily: "Nunito",
              fontWeight: 600,
            },
          },
        },
        matchBtnText: {
          margin: 0,
        },
        matchesTxt: {
          display: "flex",
          alignSelf: "flex-start",
        },
        matchDesc: {
          fontSize: "large",
        },
        boxContainer: {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: true ? "center" : "flex-start",
          gap: "1.5vw",
          width: "95%",
          height: "auto",
          marginBottom: true ? "6vh" : "2vh",
        },
        boxContainerPc: {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: true ? "center" : "flex-start",
          gap: "1.5vw",
          width: "95%",
          height: "auto",
          marginBottom: true ? "6vh" : "2vh",
        },
        glassMorphism: {
          display: "flex",
          width: "100%",
          height: "30%",
          backgroundColor: "rgba(55, 41, 41, 0.64)",
          borderRadius: "0px 0px 24px 24px",
          paddingTop: "1vh",
          paddingLeft: "2vw",
          alignItems: "flex-start",
        },
        matchTypgpy: {
          margin: 0,
          fontSize: "large",
        },
      },
    },
  },
];

export default themes;
