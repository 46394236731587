import { lazy } from 'react'
import locales from './locales'
import routes from './routes'
import themes from './themes'
import parseLanguages from 'base-shell/lib/utils/locale'
import Loading from '../components/Loading/Loading'

const config = {
  auth: {
    signInURL: '/signin',
  },
  routes,
  locale: {
    locales,
    defaultLocale: parseLanguages(['en', 'de', 'ru'], 'en'),
    onError: (e) => {
      //console.warn(e)
      return
    },
  },
  components: {
    Loading,
    /*Menu: lazy(() => import('../containers/Menu/Menu')),*/
  },
  pwa: {
    useiOSPWAPrompt: false,
    iOSPWAPromptProps: {},
  },
  /*menu: {*/
  /*width: 240,*/
  /*offlineIndicatorHeight: 12,*/
  /*initialAuthMenuOpen: false,*/
  /*initialMiniMode: false,*/
  /*initialMenuOpen: true,*/
  /*initialMobileMenuOpen: false,*/
  /*initialMiniSwitchVisibility: true,*/
  /*MenuContent: lazy(() => import('../components/Menu/MenuContent')),*/
  /*MenuHeader: lazy(() => import('../components/MenuHeader/MenuHeader')),*/
  /*useWindowWatcher: false*/
  /*},*/
  theme: {
    themes,
    defaultThemeID: 'joyfully',
    defaultIsDarkMode: false,
    defaultIsRTL: false, //change this to true for default Right to Left Language support
  },
  pages: {
    LandingPage: lazy(() => import('../pages/Home/Home')),
    PageNotFound: lazy(() => import('../pages/PageNotFound/PageNotFound')),
  },
}

export default config
