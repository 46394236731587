export const awsConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN_NAME,
      scope: [
        'email',
        'openid',
        'profile',
      ],
      redirectSignIn: `https://${process.env.REACT_APP_DOMAIN_NAME}/`,
      redirectSignOut: `https://${process.env.REACT_APP_DOMAIN_NAME}/signout`,
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  }
}
