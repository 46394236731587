const initState={
    token: null
}

export default (state=initState,action)=>{
    switch(action.type){
        case 'SIGNIN':
            return {
              ...state,
              authUser: action.payload,
              login: action.login,
            }
        case 'CONFIRMSIGNIN':
            return {
                token: action.payload.token,
                userId: action.payload.userId,
                group: action.payload.group,
            }
        case 'AUTOSIGNIN':
            return {
                token: action.payload.token,
                userId: action.payload.user_id,
                group: action.payload.group,
            }
        case 'SIGNOUT':
            return initState;
        case 'SIGNUP':
            return {
            ...state, ...action.payload
          };
        default:
            return state;
    }
}
