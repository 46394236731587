import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {useUser} from "../hooks/useUser";

function ProtectedRoute({component: Component, ...rest}) {
    const {isLoggedIn, isDoneChecking, userData} = useUser();

    return (
        <Route {...rest}
               render={(props) => {
                   if (!isDoneChecking) return null
                   if (isLoggedIn) return <Component/>
                   return <Redirect to={{pathname: '/', state: {from: props.location}}}/>
               }}
        />
    )
}

function AuthenticatedUserRoute({component: Component, ...rest}) {
    const {isLoggedIn, isDoneChecking, userData} = useUser();

    return (
        <Route {...rest}
               render={(props) => {
                   if (!isDoneChecking) return null
                   if (isLoggedIn && userData?.group !== 'uplifters'){
                      return <Component/>
                   }
                   return <Redirect to={{pathname: '/', state: {from: props.location}}}/>
               }}
        />
    )
}

function UnauthenticatedUserRoute({component: Component, ...rest}) {
    const {isLoggedIn, isDoneChecking, userData} = useUser();

    return (
        <Route {...rest}
               render={(props) => {
                   if (!isDoneChecking) return null

                   if (isLoggedIn && userData?.group === 'uplifters'){
                    return <Redirect to={{pathname: '/', state: {from: props.location}}}/>
                   } else {
                     return <Component/>
                  }
               }}
        />
    )
}

function ProtectedUplifterRoute({component: Component, ...rest}) {

    const {userData, isDoneChecking} = useUser();

    return (
        <Route {...rest}
               render={(props) => {
                   if (!isDoneChecking) return null
                   if (userData?.group === 'uplifters') return <Component/>
                   return <Redirect to={{pathname: '/signin', state: {from: props.location}}}/>
               }}
        />
    )
}

export {ProtectedRoute, ProtectedUplifterRoute, UnauthenticatedUserRoute, AuthenticatedUserRoute}
