import {combineReducers} from 'redux'
import authReducer from './authReducer'
import recordingsReducer from './recordingsReducer'
import categoriesReducer from './categoriesReducer'
import preferencesReducer from './preferencesReducer'

export default combineReducers({
    auth: authReducer,
    record: recordingsReducer,
    categories: categoriesReducer,
    preferences: preferencesReducer,
})
