const initState = {
  categories: [],
  selectedCateory: null,
}

export default (state = initState, action) => {
    switch(action.type){
        case 'FETCHCAT':
            return {
              ...state,
              all: action.payload
            }
        case 'SAVESELECTEDCATEGORY':
            return{
                ...state,
                selectedCateory: action.payload
            }
        default:
            return state;
    }
}
