import React, { lazy } from "react";
import { Route } from "react-router-dom";
import {
  ProtectedRoute,
  ProtectedUplifterRoute,
  UnauthenticatedUserRoute,
  AuthenticatedUserRoute,
} from "./ProtectedRoute";

const Home = lazy(() => import("../pages/Home/Home"));
const SignIn = lazy(() => import("../pages/Auth/SignIn"));
const SignUp = lazy(() => import("../pages/User/SignUp"));
const ConfirmSignUp = lazy(() => import("../pages/User/SignUp/ConfirmCode"));
const SignOut = lazy(() => import("../pages/Auth/SignOut"));
const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
const ConfirmCode = lazy(() => import("../pages/Auth/ConfirmCode"));
const PasswordReset = lazy(() => import("../pages/Auth/PasswordReset"));
const Profile = lazy(() => import("../pages/Auth/Profile"));
const PersonalDetails = lazy(() =>
  import("../pages/Auth/Profile/PersonalDetails")
);
const UpdatePassword = lazy(() =>
  import("../pages/Auth/Profile/UpdatePassword")
);
/*const UplifterMatches = lazy(() => import("../pages/User/UplifterMatches"));*/
const Reminder = lazy(() => import("../pages/User/Reminder/index.js"));
const Help = lazy(() => import("../pages/Help"));

//Uplifter
const UplifterSubmissions = lazy(() => import("../pages/Uplifter/Submissions"));
const UplifterConversations = lazy(() =>
  import("../pages/Uplifter/Conversations")
);
const LogOut = lazy(() => import("../pages/Uplifter/SignOut/SignOut"));
const Onboarding = lazy(() => import("../pages/Onboarding/OnboardingCurrent"));
const OnboardingQuiz = lazy(() => import("../pages/Onboarding/Quiz"));
const CreateMessageNew = lazy(() => import("../pages/User/CreateMessage"));
const ProfileScreen = lazy(() => import("../pages/Returning/ProfileScreen"));
const SubmissionResponses = lazy(() =>
  import("../pages/User/SubmissionResponses")
);
const Conversation = lazy(() => import("../pages/User/Conversation"));
const UplifterConversation = lazy(() =>
  import("../pages/Uplifter/Conversation")
);
const Submissions = lazy(() => import("../pages/User/Submissions"));
const VerifyAccess = lazy(() => import("../pages/User/VerifyAccess"));
const HomePage = lazy(() => import("../pages/Home/HomePage"));
const Subscription = lazy(() => import("../pages/Subscription"));
const SelfCareCheckIn = lazy(() => import("../pages/User/SelfCareCheckIn"));
const SelfCareCheckInResults = lazy(() =>
  import("../pages/User/SelfCareCheckIn/Results/index.js")
);
const ToDoList = lazy(() => import("../pages/User/ToDoList"));
const WeeklyProgress = lazy(() => import("../pages/User/WeeklyProgress"));

const routes = [
  <Route path="/help" exact component={Help} />,
  //Uplifter
  <ProtectedUplifterRoute
    path="/uplifters/home"
    redirectTo="/"
    exact
    component={UplifterSubmissions}
  />,
  <ProtectedUplifterRoute
    path="/uplifters/conversations"
    redirectTo="/"
    exact
    component={UplifterConversations}
  />,
  <ProtectedUplifterRoute
    path="/uplifters/conversations/:id"
    exact
    component={UplifterConversation}
  />,
  <ProtectedRoute path="/logout" redirectTo="/" exact component={LogOut} />,
  //User
  <ProtectedRoute path="/signout" redirectTo="/" exact component={Home} />,
  <AuthenticatedUserRoute
    path="/make-submission"
    exact
    component={OnboardingQuiz}
  />,
  <AuthenticatedUserRoute
    path="/users/submissions/:id"
    exact
    component={SubmissionResponses}
  />,
  <AuthenticatedUserRoute
    path="/users/create-message"
    exact
    component={CreateMessageNew}
  />,
  <AuthenticatedUserRoute
    path="/users/submissions"
    exact
    component={Submissions}
  />,
  <AuthenticatedUserRoute
    path="/conversations/:id"
    exact
    component={Conversation}
  />,
  <AuthenticatedUserRoute
    path="/users/checkin"
    exact
    component={SelfCareCheckIn}
  />,
  <AuthenticatedUserRoute
    path="/users/checkin/:id"
    exact
    component={SelfCareCheckIn}
  />,
  <AuthenticatedUserRoute
    path="/users/checkin-results"
    exact
    component={SelfCareCheckInResults}
  />,
  <AuthenticatedUserRoute path="/users/todo-list" exact component={ToDoList} />,
  <AuthenticatedUserRoute
    path="/users/weekly-progress"
    exact
    component={WeeklyProgress}
  />,

  <ProtectedRoute
    path="/profile/personal-details"
    exact
    component={PersonalDetails}
  />,
  <ProtectedRoute
    path="/profile/change-password"
    exact
    component={UpdatePassword}
  />,
  <ProtectedRoute path="/users/more-options" exact component={ProfileScreen} />,

  //Authentication
  <Route path="/signin" redirectTo="/" exact component={SignIn} />,
  <Route path="/users/signup" redirectTo="/" exact component={SignUp} />,
  <Route
    path="/users/signup/confirm"
    redirectTo="/"
    exact
    component={ConfirmSignUp}
  />,
  <Route
    path="/forgot_password"
    redirectTo="/"
    exact
    component={ForgotPassword}
  />,
  <Route
    path="/password_reset"
    redirectTo="/"
    exact
    component={PasswordReset}
  />,
  <Route path="/confirm_code" redirectTo="/" exact component={ConfirmCode} />,
  <Route path="/home" exact component={Home} />,
  <Route path="/homepage" exact component={HomePage} />,
  <Route path="/users/home" exact component={HomePage} />,
  <Route path="/user/profile" exact component={Profile} />,
  /*<Route path="/users/uplifter-matches" exact component={UplifterMatches} />,*/
  <Route path="/users/reminder" exact component={Reminder} />,
  <UnauthenticatedUserRoute path="/onboarding" exact component={Onboarding} />,
  <UnauthenticatedUserRoute
    path="/onboarding/quiz/:id"
    exact
    component={OnboardingQuiz}
  />,
  <UnauthenticatedUserRoute
    path="/users/verify-access"
    exact
    component={VerifyAccess}
  />,
  <AuthenticatedUserRoute
    path="/users/subscribe"
    exact
    component={Subscription}
  />,
];

export default routes;
