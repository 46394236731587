const initState = {
  preferRecentUplifter: false,
}

export default (state = initState, action) => {
    switch(action.type){
        case 'SETPREFERENCE':
            return {
              ...state,
              ...action.payload
            }
        default:
            return state;
    }
}
