import React, { Component } from "react";
import App from "base-shell/lib";
import MUIConfig from "material-ui-shell/lib";
import merge from "base-shell/lib/utils/config";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import _config from "./config";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducers from "./store/reducers";
import Amplify from "aws-amplify";
import { awsConfig } from "./awsConfig";
import * as Sentry from "@sentry/react";
import UserContextProvider from "./hooks/useUser";
import TaskContextProvider from "./hooks/useTasks";
import { BrowserRouter } from "react-router-dom";
import UpdateContainer from "containers/UpdateContainer/UpdateContainer";
import { red } from "@mui/material/colors";

import { SnackbarProvider } from "notistack";

import COLORS from "constants/colors";

const config = merge(MUIConfig, _config);
const store = createStore(reducers, applyMiddleware(thunk));

Amplify.configure(awsConfig);

let theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
      darker: "#053e85",
      inactive: "#10545D54",
      light: COLORS.lightPrimary,
      green: "#10545D",
      success: "#10545D",
      danger: "#d01212",
    },
    secondary: {
      main: "#F79256",
      light: "#F79256A1",
    },
    neutral: {
      main: "#10545D",
      contrastText: "#fff",
    },
    divider: COLORS.lightPrimary,
    action: {
      disabledBackground: "#10545D54",
      disabled: "#FFFFFF",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      danger: red[900],
    },
  },
  status: {
    danger: "#e53e3e",
  },
  background: COLORS.background,
  shape: {
    borderRadius: 15,
  },
  matchesBtnText: {
    margin: 0,
  },

  typography: {
    fontFamily: "Nunito",
    fontSize: 14,
    boldCompact: {
      fontWeight: 700,
      fontSize: "1rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2rem",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: ".8rem",
      color: COLORS.primaryMuted,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamilt: "Nunito",
        fontWeight: 400,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: "1rem",
          fontFamily: "Nunito",
          fontWeight: 700,
          fontStyle: "normal",
        },
      },
      defaultProps: {
        variant: "contained",
        size: "medium",
        fullWidth: true,
        color: "primary",
      },
    },
    MuiLink: {
      defaultProps: {
        variant: "body1",
        color: "secondary",
      },
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.h2 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.2rem",
  },
};

theme.header = {
  height: theme.spacing(2)
};

export default class Joyfully extends Component {
  componentWillMount() {
    if (
      window.location.protocol === "http:" &&
      process.env.NODE_ENV == "production"
    ) {
      const secureUrl = window.location.href.replace("http:", "https:");
      window.location.href = secureUrl;
    }
  }

  render() {
    return (
      <BrowserRouter>
        <UserContextProvider>
          <TaskContextProvider>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                  <UpdateContainer>
                    <App config={config} />
                  </UpdateContainer>
                </SnackbarProvider>
              </ThemeProvider>
            </Provider>
          </TaskContextProvider>
        </UserContextProvider>
      </BrowserRouter>
    );
  }
}
