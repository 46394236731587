import axios from "axios";
import { Auth } from "aws-amplify";
import { clearItem } from "storage/storage";

const API_URL = process.env.REACT_APP_API_URL;

const request = async (resource, params = {}) => {
  let headers = {
    "Content-Type": "application/json",
  };

  try {
    const session = await Auth.currentSession();
    const cognitoIdToken = session.getIdToken();
    const token = cognitoIdToken.getJwtToken();
    headers = getHeaders(token);
    /*console.log(token)*/
  } catch (e) {
    if (e === "No current user") {
      clearItem("userData");
      /*window.location.href = "/signin"*/
    }
  }

  return fetch(`${API_URL}${resource}`, { headers, ...params }).then(
    async (response) => {
      return response.json();
    }
  );
};

const getHeaders = (token) => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const categoriesApi = {
  async recentUplifterCategory() {
    return request(`/categories?recent_uplifter=true`);
  },
  async uplifterCategories(uplifterId) {
    return request(`/uplifters/${uplifterId}/categories`);
  },
  async fetch(group_id) {
    return request(`/categories?group_id=${group_id}`);
  },
};

export const usersApi = {
  async whoami(token) {
    return request(`/whoami`);
  },

  async uplifter() {
    return request(`/whoami/uplifters`);
  },
};

export const quizApi = {
  async create(quiz) {
    const params = {
      method: "POST",
      body: JSON.stringify(quiz),
    };

    return request(`/users/quiz-responses`, params);
  },
  async update(quiz) {
    const params = {
      method: "PUT",
      body: JSON.stringify(quiz),
    };

    return request(`/users/quiz-responses`, params);
  },
};
export const onboardingApi = {
  async start() {
    return request(`/users/onboarding-submission`);
  },
  async fetchQuiz() {
    return request(`/users/onboarding-quiz`);
  },
  async fetchResponses(conversationId) {
    return request(`/users/quiz-responses?conversation_id=${conversationId}`);
  },
  async completePending(token, cognitoId) {
    const params = {
      method: "PUT",
      body: JSON.stringify({ cognito_id: cognitoId }),
    };

    return request(`/users/onboarding-submission?token=${token}`, params);
  },
};
export const checkInApi = {
  async fetchCheckin() {
    return request(`/users/checkin-quiz`);
  },
  async fetchCheckinResponses() {
    return request(`/users/checkin-responses`);
  },
  async create(checkInQuiz) {
    const params = {
      method: "POST",
      body: JSON.stringify(checkInQuiz),
    };

    return request(`/users/checkin-responses`, params);
  },
};
export const toDoListApi = {
  async fetchToDoList() {
    return request(`/users/todos`);
  },
  async update(todoItemId) {

    const params = {
      method: "PUT",
      body: JSON.stringify({
        completed: true,
      }),
    };

    return request(`/users/todos/${todoItemId}`, params);
  },
};

export const submissionsApi = {
  async get(id) {
    return request(`/submissions/${id}`);
  },
  async userSubmissions() {
    return request("/users/submissions");
  },
  async rootSubmissions() {
    return request("/users/root-submissions");
  },
  async fetch_kinds() {
    return request("/submission_kinds");
  },
  async post(options, onboarding = false) {
    const { userId, formData } = options;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    if (onboarding) {
      return axios.post(
        `${API_URL}/users/onboarding-submission?token=${options.token}`,
        formData,
        {
          headers: headers,
        }
      );
    }
  }
};

export const checkOutApi = {
  async createSession(params) {
    const body = {
      body: JSON.stringify(params),
      method: "POST",
    };
    return request(`/checkout/session`, body);
  },
};

export const stripeApi = {
  async portalSession() {

    const body = {
      body: "",
      method: 'POST'
    }
    return request(`/stripe/portal`, body);
  }
}

export const feedBackApi = {
  async post(options) {
    const params = {
      method: "POST",
      body: JSON.stringify(options),
    };

    return request(`/feedback`, params);
  },
};

export const accessCodeApi = {
  async verify(code) {
    const params = {
      method: "POST",
      body: JSON.stringify({ code }),
    };

    return request(`/access-codes/verify`, params);
  },
};

export const responsesApi = {
  async fetch() {
    return request("/users/responses");
  },
  async submissionResponses(id) {
    return request(`/submissions/${id}/responses`);
  },
  async update(responseId, options) {
    const params = {
      method: "PUT",
      body: JSON.stringify(options),
    };

    return request(`/responses/${responseId}`, params);
  },

  async post(options) {
    const { formData } = options;

    const session = await Auth.currentSession();
    const cognitoIdToken = session.getIdToken();
    const token = cognitoIdToken.getJwtToken();

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    return axios.post(`${API_URL}/responses`, formData, {
      headers: headers,
    });
  },

  async get(response_id) {
    return request(`/responses/${response_id}`);
  },
};

export const recordingsApi = {
  async fetch(per_page, page) {
    return request(`/submissions?per_page=${per_page}&page=${page}`);
  },
};

export const referralApi = {
  async fetch() {
    return request("/referrals");
  },
};

export const singleRecordingApi = {
  async fetch(id) {
    return request(`/submissions/${id}`);
  },
};

export const conversationApi = {
  async update(id, options) {
    const params = {
      method: "PUT",
      body: JSON.stringify(options),
    };

    return request(`/conversations/${id}`, params);
  },
  async get(id) {
    return request(`/conversations/${id}`);
  },

  async fetch(per_page, page, status) {
    return request(
      `/conversations?per_page=${per_page}&page=${page}&status=${status}`
    );
  },

  getLatest(submissionId) {
    return request(`/conversations/latest?submission_id=${submissionId}`);
  },
};
