export default {
  primary: "#10545D",
  lightPrimary: "#10545D54",
  secondary: "#F79256",
  lightSecondary: "#eec2ae",
  inactive: "#E0E4DA",
  background: "#fff9ed",
  dark: "#000814",
  primaryMuted: "#01010163",
  zero: 0,
};
